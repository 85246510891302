#overview-container{
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding-bottom: 2em;
}

#overview-wrapper{
  display: flex;
}

#overview-description{
  text-align: justify;
  margin-right: 2em;
  width: 50%;
}

#overview-images-container {
  display: flex;
  flex-wrap: wrap;
  width: 50%;
  gap: 1em;
}
  
#overview-images-container > * {
  width: 48%;
  height: 48%;
}

.overview-images{
    border-radius: 1em;
}
  
