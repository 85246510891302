#category_card_container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 18%;
    background-color:  rgb(5, 5, 5);
    border-radius: 1em;
    border: 1px solid rgb(75, 75, 75);
}

.category_image{
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

#category_name_h3{
    margin: 1em;
}