#login-title{
    margin-bottom: 10%;
}

#login-hr{

    width: 100%;
    border: 1px solid rgb(75, 75, 75);
}

#login-hr2{
    margin: 10% 0px;
    width: 100%;
    border: 1px solid rgb(75, 75, 75);
}

#login-email-container{
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    margin-bottom: 10%;
    margin-top: 10%;
}


#login-password-container{
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    margin-bottom: 10%;
}

#login-signup-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

#login-button{
    display: flex;
    justify-content: center;
    margin-bottom: 5%;
}

#demo-container{
    display: flex;
    justify-content: center;
    gap: 5%;
}
