.hidden {
  display: none;
}

#nav_container {
  grid-area: nav;
  display: flex;
  justify-content: center;
  background-color: rgb(22, 22, 22);
  position: sticky;
  border-bottom: 1px solid rgb(75, 75, 75);
  top: 0px;
  z-index: 4;
  height: 75px;
}

#nav_links_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;
}

#nav_links_left {
  display: flex;
  align-items: center;
  gap: 3em;
}

#logo-container {
  height: 50px;
}

#logo {
  width: 100%;
  height: 100%;
}

#nav_links_right {
  display: flex;
  max-width: 300px;
  justify-content: space-between;
  align-items: center;
  gap: 3em;
}

#profile_menu_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
}

#loginButtons > button {
  background: none;
  border: none;
  color: white;
  font-family: "Belanosima", serif;
  font-size: x-large;
  padding: 20px;
}

#modal-content {
  background-color: rgb(24, 26, 27);
}

.profile-dropdown {
  padding: 1em;
  background-color: rgb(22, 22, 22);
  margin-top: 1em;
  position: absolute;
  right: 0px;
  top: 40px;
    border: 1px solid rgb(75, 75, 75);
}

.profile-dropdown > li {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#logOut {
  background: none;
  border: none;
  font-family: "Belanosima", serif;
  color: white;
}

#logOut:hover {
  cursor: pointer;
}

#logOutLi {
  border-top: 1px solid;
  border-color: rgb(140, 130, 115);
  margin-left: 4px;
  margin-right: 4px;
  /* padding-left: -10px; */
}

#userButtons > a {
  color: rgb(245, 160, 85);
}

#dropNavs {
  display: flex;
  flex-direction: column;
  padding-top: 0px;
  border-top: 1px solid;
  margin-left: 4px;
  margin-right: 4px;
}

#dropNavs > a {
  color: white;
  margin-top: 10px;
  text-decoration: none;
}

.navi-icons {
  display: flex;
  justify-content: space-between;
  height: 100%;
  gap: 2em;
  align-items: center;
  margin: 0px 0px;
  text-wrap: nowrap;
}

#shop-link {
  padding-right: 70%;
}

#shop-link > a {
  color: white;
  text-decoration: none;
}

#home-link > a {
  color: white;
  text-decoration: none;
}
