/* TODO Add site wide styles */

html, body {
  margin: 0px !important;
  padding: 0px !important;
  font-family: "Gordita", Helvetica, Arial, Verdana, sans-serif;
  height: 100%;
  background-color:  rgb(10, 10, 10);
  color: white;
}

#root {
  font-family: "Gordita", Helvetica, Arial, Verdana, sans-serif;
  height: 100%;
  min-width: 1000px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 76px 1fr 76px;
  grid-template-areas: 
  "nav"
  "main"
  "footer";
}

#main-content{
  grid-area: main;
  display: flex;
  flex-direction: column;
  width: 70%;
  margin-left: 15%;
  margin-right: 15%;
  margin-top: 2em;
}

ul {
  list-style: none;
  padding: none !important;
  margin: none !important;
}

#modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 20px;
}

#modal-content > form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#modal-content > form > input {
  background-color:  rgb(10, 10, 10);
  border-radius: 5px;
  color: lightgrey;
  margin: 5px;
  border: lightgrey 2px solid;
  padding: 10px;
  padding-right: 150px;
  font-family: "Belanosima", serif;
  font-size: large;
}

#modal-content > form > button,
#modal-content > button {
  border: white solid 3px;
  padding: 10px;
  width: fit-content;
  font-family: "Belanosima", serif;
  font-size: medium;
}

#modal-content > #noDelete {
  background-color: #f44a07;
  background-color: grey;
}

#modal-content > form > button:hover,
#modal-content > button:hover {
  cursor: pointer;
}

#modal-content > form > button:disabled,
#modal-content > button:disabled {
  background-color: grey;
}

#modal-content > #demoButton:hover {
  cursor: pointer;
}

img {
  object-fit: cover;
}

h1 {
  font-size: 24px;
  margin: 0px;
  padding: 0px;
  color: white;
}

h2 {
  font-size: 20px;
  margin: 0px;
  padding: 0px;
  color: white;
}

h3 {
  font-size: 16px;
  margin: 0px;
  padding: 0px;
  color: white;
}

a {
  color: white;
  text-decoration: none;
}

a:hover {
  color: white;
}

button {
  cursor: pointer;
  font-weight: bold;
  color: white;
  background-color: black;
  font-size: medium;
  border: 1px solid rgb(75, 75, 75);
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
  width: fit-content;
  transition: .5s;
}

button:hover {
  background-color: #f44a07;
  color: black;
}

#checkout {
  margin-bottom: 10px;
  margin-top: 10px;
}

#emptyCart {
  display: flex;
  flex-direction: column;
}

.emptyCartButt {
  margin: 5px;
  padding: 10px;
}
