#product_card_container {
  display: flex;
  flex-direction: column;
  gap: .5em;
  width: 375px;
  height: 375px;
  background-color: rgb(22, 22, 22);
  border: 1px solid rgb(75, 75, 75);
  border-radius: 1em;
  overflow-wrap: break-word;
  word-break: break-word;
}

#product_card_image {
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    object-fit: cover;
    width: 100%;
    height: 60%;
}

#product_text_container{
  display: flex;
  flex-direction: column;
  height: 40%;
  justify-content: space-around;
  margin-left: 1em;
  margin-right: 1em;
}

#manage_product_button_container{
  display: flex;
  justify-content: center;
}