#overview-specs-container {
  display: flex;
  justify-content: left;
}

#individual-spec {
  padding-bottom: 10px;
}

#specs-list {
  width: 80%;
}
