#product-details-container{
  display: flex;
  flex-direction: column;
  gap: 2em;
}

#product_details_main_content_container {
  display: flex;
  gap: 3em;
  padding-bottom: 2em;
}

/* IMAGE CAROUSEL */

/* *, *::before, *::after {

} */

.carousel {
  flex-basis: 50%;
  width: 50%;
  height: 300px;
  position: relative;
}

.carousel > ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.slide {
  position: absolute;
  inset: 0;
  opacity: 0;
  transition: 200ms opacity ease-in-out;
  transition-delay: 200ms;
}

.slide > img {
  display: block;
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
  border-radius: 1em;
  border: 1px solid rgb(75, 75, 75);
}

.slide[data-active] {
  opacity: 1;
}

.carousel-button {
  position: absolute;
  z-index: 2;
  background: none;
  border: none;
  font-size: 4rem;
  top: 85%;
  cursor: pointer;
  border-radius: 0.25rem;
  padding: 0 0.5rem;
  color: rgb(206, 206, 206);
  background-color: rgb(5, 5, 5);
}

.carousel-button:hover {
  color: #f44a07;
  background-color: rgb(5, 5, 5);
}

.carousel-button:focus {
  /* color: #f44a07; */
  outline: 1px solid rgb(255, 255, 255);
}

.carousel-button.prev {
  left: 1rem;
}

.carousel-button.next {
  right: 1rem;
}

/* //////////////////////////////////////////////////// */

#product_details_product_main_info {
  width: 50%;
  text-align: justify;
}

#switch-view-container {
  display: flex;
  justify-content: center;
  gap: 10em;
  border-top: 1px solid rgb(75, 75, 75);
  border-bottom: 1px solid rgb(75, 75, 75);
}

#product_details_product_description {
  word-wrap: break-word;
}
