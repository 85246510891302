#product-form-container {
  width: 450px;
}

#product-form-h3 {
  text-align: center;
  border-bottom: 1px solid lightgray;
  padding-bottom: 0.5em;
  margin-bottom: 0.5em;
}

#product-form {
  display: flex;
  flex-direction: column;
  gap: 1em;
  max-height: 560px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 1em;
}

.product-form-field {
  display: flex;
}

.product-form-label {
  width: 175px;
}

.product-form-input {
  width: 60%;
}

.product-form-field-container-large {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.product-form-label-large {
  width: 100%;
}

.product-form-field-large {
  display: flex;
  flex-direction: column;
}

.error-message {
  color: red;
  font-size: small;
  padding: 0;
  margin: 0px 0px 5px 0px;
  text-align: right;
}

#product-product-description-field {
  width: 100%;
}

#product-form-price-label {
  display: flex;
  align-items: center;
}

#form-dollar-sign {
  margin-bottom: 0.5em;
}

.product-button-container {
  display: flex;
  justify-content: left;
  width: 100%;
  margin: 0.25em;
}
