/* REVIEWS COMPONENT */

#review-component-main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-wrap: break-word;
    word-break: break-word;

}

#product-post-review-test-container {
    display: flex;
    align-items: center;
}

#product-post-review-test-container > h2 {
    font-size: 50px;
    margin-right: 110px;
}

#create-review-modal-button-container {
    position: relative;
    right: 200px;
}

#product-review-details-overview-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 10px;
}


/* REVIEW HEADER INFO */

#star-rating-mapping-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.star-fill-average-rating {
    color: gold;
    font-size: 24px;
}

#review-detail-view-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#review-detail-view-container > p {
    font-size: 13px;
}

#reviewCount {
    font-size: 13px;
    margin-top: 2px;
    margin-left: 3px;
}


/* MAIN REVIEWS DISPLAY */

/* #reviews-container {

} */

#single-review {
    border-top: 1px solid grey;
    display: flex;
    padding: 5px 0px;
}

.review-user-info {
    margin-right: 10px;
    flex-basis: 10%;
}

.review-info {
    flex-basis: 90%;
}

#verified-buyer {
    font-size: 13px;
}

.stars {
    display: flex;
    flex-direction: column;
}

.star-errors {
    font-size: 13px;
    padding: 0px;
    margin: 0px;
}
