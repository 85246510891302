#shop-by-category{
    display: flex;
    justify-content: center;
    margin: 1.5em;
}

#categories_index{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1em;
    padding-bottom: 2em;
}

/* CAROUSEL */

#carousel-div-container{
    display: flex;
    justify-content: right;
}

#carousel-div {
    width: 90%;
    position: relative;
}

#carousel-div > ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.slide-li {
    inset: 0;
    opacity: 0;
    /* transition: 200ms opacity ease-in-out;
    transition-delay: 200ms; */
}

.active {
    inset: 0;
    opacity: 1;
    margin-left: auto;
    margin-right: auto;
}

.active > img {
    display: block;
    width: 100%;
    aspect-ratio: 5/2;
    object-fit: cover;
    border: 1px solid rgb(75, 75, 75);
    border-radius: 1em;
}

#carousel_cycle_text_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    position: absolute;
    top: 37%;
    left: -11%;
    background-color: rgb(5, 5, 5);
    width: 20%;
    height: 150px;
    border-radius: 1em;
    padding: 10px 10px;
    border: 1px solid rgb(75, 75, 75);
}

.cycle-text {
    color: white;
    text-align: center;
}

#text-move {
    display: flex;
    justify-content: center;
}
